import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { observer } from "mobx-react-lite"
import { useStore } from "../../stores"
import {
  EuiBadge,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import {
  getBookingDateTimeUIFormatted,
  getBookingDateUIFormatted,
  getBookingTimeUIFormatted,
  getCompletionDateUIFormatted,
} from "../../entities/utilities"
import { RepairTypes } from "../../entities/constants"
import SummaryItem from "../../entities/summaryItems"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"
import WaitlistActionFooter from "../../components/booking/waitlist-action-footer"

//1. extract customer id from url
//2. Get current booking of customer by customer id
//3. Get available slots by customer id

const WaitlistKeepBooking = () => {
  const [bookingSummary, setBookingSummary] = useState<SummaryItem[]>([])
  const { customerStore, facilityStore, bookingStore } = useStore()
  const { facility } = facilityStore
  const { currentBooking } = bookingStore

  const data = {
    title: "Waitlist",
  }

  const handleSecondaryClick = () => {
    navigate("/book/waitlist")
  }

  useEffect(() => {
    if (currentBooking) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === currentBooking.repairType
      )

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.Clock} alt="Drop Off" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Drop off</strong>
                  <br />
                  {`${getBookingDateTimeUIFormatted(
                    currentBooking?.repairDate,
                    currentBooking?.dropOffTime
                  )}`}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CalendarIcon} alt="Completion" />,
            description: (
              <EuiText grow={false}>
                <p>
                  <strong>Estimated completion</strong>
                  <br />
                  {getCompletionDateUIFormatted(
                    currentBooking?.repairDate,
                    repairType.durationInWeeks
                  )}
                </p>
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CarRepair} alt="Repair Type" />,
            description: (
              <EuiText grow={false}>
                Repair required
                <br />
                <strong>{repairType.name}</strong>
                <br />
                <p className="highlightedText">
                  {repairType.durationDescription}
                </p>
              </EuiText>
            ),
          },
        ]

        setBookingSummary([...summary])
      }
    }
  }, [])

  return (
    <HailCXLayout
      header={
        <HailCXRightImageHeader
          title={data.title}
          progressValue={0}
          vehicleInfo={customerStore.getVehicleInfo()}
        />
      }
      footer={<WaitlistActionFooter />}
    >
      <EuiText
        grow={false}
        className="eui-textLeft"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      >
        <h1>Your current booking</h1>
        <p>
          You've confirmed you wish to keep your current booking as per below.{" "}
        </p>
        <p>
          If you didn't add yourself to Waitlist at the time of booking, and you
          would like to be added to the Waitlist for a possible earlier repair,
          press Waitlist button below. Otherwise you can remove yourself from
          the Waitlist.
        </p>
      </EuiText>
      <EuiSpacer />
      <EuiPanel>
        <EuiDescriptionList type="column" listItems={bookingSummary} />
      </EuiPanel>
    </HailCXLayout>
  )
}

export default observer(WaitlistKeepBooking)
